




























































import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { passwordCriteria } from "@/misc/PasswortCriterias";
import UserRepository from "@/api/repositories/UserRepository";
import { namespace } from "vuex-class";
import {
  AUTH_STORE_NAME,
  AuthStoreActions,
  AuthStoreGetters
} from "@/store/auth.store";
import User from "@/models/User.model";
import EasyCard from "@/models/EasyCard.model";
import { ApplicationBufferResponse } from "@/api/repositories/application.models";
import fileDownload from "js-file-download";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({})
export default class OrderECardComponent extends mixins(
  ErrorMessageHandlerMixin
) {
  public items: number[] = Array.from({ length: 50 }, (_, i) => i + 1);

  @AuthStore.Action(AuthStoreActions.ORDER_EASY_CARDS)
  private orderEasyCards!: (payload: {
    easyCards: EasyCard[],
    comment: string
  }) => Promise<ApplicationBufferResponse>;

  private cards: number = 1;

  public comment: string = "";

  private loading: boolean = false;

  public name: string = "";

  private async orderCards() {
    // Form is Valid try to Register a new Account
    try {
      this.loading = true;
      const easyCards = Array.from({ length: this.cards }, (_, __) => EasyCard.parseFromObject({ name: this.name }));
      const orderData = await this.orderEasyCards({ easyCards: easyCards, comment: this.comment });

      // Registration Successful toast
      this.$notifySuccessSimplified("GENERAL.NOTIFICATIONS.ORDERED");

      // Download the file
      fileDownload(new Blob([orderData.buffer]), `Antrag-${orderData.app.referenceNumber}.pdf`);

      // Close Dialog
      this.dismiss();
    } catch (e) {
      this.$handleError(e, () => this.$notifyErrorSimplified("GENERAL.NOTIFICATIONS.ERROR_GENERAL"));
    } finally {
      this.loading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$emit("closeDialog", reload);
  }
}
