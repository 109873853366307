import {AccountType} from '@/enum/AccountType.enum';
import Parseable from '@/misc/Parseable';
import User from '@/models/User.model';

export default class EasyCard extends Parseable {
  public name!: string;

  public static parseFromObject(object: any): EasyCard {
      const easy = new EasyCard();
      Object.assign(easy, object);
      return easy;
  }

  public parseToObject(): Partial<EasyCard> {
      return { ...this };
  }
}

